import { all, takeLatest, call, put } from 'redux-saga/effects';

import handleError from '@/adapters/httpErrors';
import codesProvider from '@/lib/codes';

import CodesActions, { CodesTypes } from './duck';
import CodeDTO from '@/dtos/solicitations/CodeDTO';
import PageDTO from '@/dtos/generics/PageDTO';
import { AxiosError } from 'axios';

type ListParams = ReturnType<typeof CodesActions.listRequest>;
export function* list({ payload }: ListParams): Generator {
  try {
    const result = yield call(codesProvider.list, payload.filters);
    yield put(CodesActions.listSuccess(result as PageDTO<CodeDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(CodesActions.listError(errors));
  }
}

type GetParams = ReturnType<typeof CodesActions.getCodeRequest>;
export function* find({ payload }: GetParams): Generator {
  try {
    const result = yield call(codesProvider.find, payload.value);
    yield put(CodesActions.getCodeSuccess(result as CodeDTO));
  } catch (err) {
    const axiosError = err as AxiosError;
    let errorMessage = 'Ocorreu um erro ao buscar o código.';
    if (axiosError.response) {
      if (axiosError.response.status === 403) {
        errorMessage = 'Você não tem permissão para visualizar este código.';
      } else if (axiosError.response.status === 404) {
        errorMessage = 'Código não encontrado.';
      } else {
        errorMessage = axiosError.response.data.message || errorMessage;
      }
    }
    yield put(CodesActions.getCodeError(errorMessage));
  }
}

export default all([
  takeLatest(CodesTypes.LIST_REQUEST, list),
  takeLatest(CodesTypes.GET_CODE_REQUEST, find),
]);
