import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';
import { CreateAggregationDTO } from '@/dtos/aggregations/CreateAggregationDTO';
import { UpdateAggregationDTO } from '@/dtos/aggregations/UpdateAggregationDTO';
import AggregationDTO from '@/dtos/aggregations/AggregationDTO';

const aggregationProvider = {
  async list(
    filters: SearchQuery,
    page?: number
  ): Promise<PageDTO<AggregationDTO>> {
    const { data } = await api.get<PageDTO<AggregationDTO>>(`/aggregations`, {
      params: { ...filters.query, page },
    });
    return data;
  },

  async getById(id: string): Promise<AggregationDTO> {
    const { data } = await api.get<AggregationDTO>(`/aggregations/${id}`);
    return data;
  },

  async getByAggregatorCode(aggregatorCode: string): Promise<AggregationDTO> {
    try {
      const response = await api.get<AggregationDTO>(
        `/aggregations/${aggregatorCode}`
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw new Error('Aggregation not found');
      } else {
        throw new Error('Failed to fetch aggregation');
      }
    }
  },

  async getByChildCode(childCode: string): Promise<AggregationDTO | null> {
    try {
      const response = await api.get<AggregationDTO>(
        `/aggregations/child/${childCode}`
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return null;
      } else {
        console.error('Erro ao buscar o código pai:', error);
        throw new Error('Erro ao buscar código pai');
      }
    }
  },

  async create(aggregation: CreateAggregationDTO): Promise<AggregationDTO> {
    const { data } = await api.post<AggregationDTO>(
      '/aggregations',
      aggregation
    );
    return data;
  },

  async update(aggregation: UpdateAggregationDTO): Promise<AggregationDTO> {
    const { data } = await api.put<AggregationDTO>(
      `/aggregations/${aggregation.aggregatorCode}`,
      aggregation
    );
    return data;
  },

  async disaggregate(aggregatorCode: string, childCode: string): Promise<void> {
    await api.delete(`/aggregations/${aggregatorCode}`, {
      params: { childCode },
    });
  },
};

export default aggregationProvider;
