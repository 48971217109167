import { AnyAction, Reducer } from 'redux';
import { action, createReducer } from 'typesafe-actions';

import { PageDTO } from '@/dtos/generics';
import { StatusFieldDTO } from '@/dtos/statusField';
import { StatusDTO, AddStatusDTO, UpdateStatusDTO } from '@/dtos/status';
import SearchQuery from '@/utils/SearchQuery';

import { genericError, genericRequest, genericSuccess } from '../utils';
import { StatusState } from './types';

export enum StatusTypes {
  SET_FILTERS = '@status/setFilters',

  LIST_REQUEST = '@status/listRequest',
  LIST_SUCCESS = '@status/listSuccess',
  LIST_ERROR = '@status/listError',

  GET_STATUS_FIELDS_REQUEST = '@status/getStatusFieldsRequest',
  GET_STATUS_FIELDS_SUCCESS = '@status/getStatusFieldsSuccess',
  GET_STATUS_FIELDS_ERROR = '@status/getStatusFieldsError',

  GET_REQUEST = '@status/getRequest',
  GET_SUCCESS = '@status/getSuccess',
  GET_ERROR = '@status/getError',

  ADD_REQUEST = '@status/addRequest',
  ADD_SUCCESS = '@status/addSuccess',
  ADD_ERROR = '@status/addError',

  UPDATE_REQUEST = '@status/updateRequest',
  UPDATE_SUCCESS = '@status/updateSuccess',
  UPDATE_ERROR = '@status/updateError',

  DELETE_REQUEST = '@status/DELETE_REQUEST',
  DELETE_SUCCESS = '@status/DELETE_SUCCESS',
  DELETE_ERROR = '@status/DELETE_ERROR',

  ACTIVE_REQUEST = '@status/ACTIVE_REQUEST',
  ACTIVE_SUCCESS = '@status/ACTIVE_SUCCESS',
  ACTIVE_ERROR = '@status/ACTIVE_ERROR',

  CLEAR_EVENTS = '@CLEAR_EVENTS',
  CLEAR_SELECTED = '@status/clearSelected',
  GET_STATUS_REQUEST = 'GET_STATUS_REQUEST',

  LIST_COMPANY_STATUSES_REQUEST = '@status/listCompanyStatusesRequest',
  LIST_COMPANY_STATUSES_SUCCESS = '@status/listCompanyStatusesSuccess',
  LIST_COMPANY_STATUSES_ERROR = '@status/listCompanyStatusesError',

  LIST_SYSTEM_STATUSES_REQUEST = '@status/listSystemStatusesRequest',
  LIST_SYSTEM_STATUSES_SUCCESS = '@status/listSystemStatusesSuccess',
  LIST_SYSTEM_STATUSES_ERROR = '@status/listSystemStatusesError',
}

const StatusActions = {
  setFilters: (filters: SearchQuery) =>
    action(StatusTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery) =>
    action(StatusTypes.LIST_REQUEST, { filters }),

  listSuccess: (page: PageDTO<StatusDTO>) =>
    action(StatusTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(StatusTypes.LIST_ERROR, { error }),

  getRequest: (id: string) => action(StatusTypes.GET_REQUEST, id),
  getSuccess: (status: StatusDTO) => action(StatusTypes.GET_SUCCESS, status),
  getError: (error: string) => action(StatusTypes.GET_ERROR, { error }),

  getStatusFieldsRequest: () => action(StatusTypes.GET_STATUS_FIELDS_REQUEST),
  getStatusFieldsSuccess: (fields: StatusFieldDTO[]) =>
    action(StatusTypes.GET_STATUS_FIELDS_SUCCESS, fields),
  getStatusFieldsError: (error: string) =>
    action(StatusTypes.GET_STATUS_FIELDS_ERROR, { error }),

  addRequest: (status: AddStatusDTO) => action(StatusTypes.ADD_REQUEST, status),
  addSuccess: () => action(StatusTypes.ADD_SUCCESS),
  addError: (error: string) => action(StatusTypes.ADD_ERROR, { error }),

  updateRequest: (status: UpdateStatusDTO) =>
    action(StatusTypes.UPDATE_REQUEST, status),
  updateSuccess: () => action(StatusTypes.UPDATE_SUCCESS),
  updateError: (error: string) => action(StatusTypes.UPDATE_ERROR, { error }),

  deleteRequest: (id: string) => action(StatusTypes.DELETE_REQUEST, id),
  deleteSuccess: () => action(StatusTypes.DELETE_SUCCESS),
  deleteError: (error: string) => action(StatusTypes.DELETE_ERROR, { error }),

  activeRequest: (id: string) => action(StatusTypes.ACTIVE_REQUEST, id),
  activeSuccess: () => action(StatusTypes.ACTIVE_SUCCESS),
  activeError: (error: string) => action(StatusTypes.ACTIVE_ERROR, { error }),

  clearEvents: () => action(StatusTypes.CLEAR_EVENTS),
  clearSelected: () => action(StatusTypes.CLEAR_SELECTED),

  listCompanyStatusesRequest: (filters: SearchQuery) =>
    action(StatusTypes.LIST_COMPANY_STATUSES_REQUEST, { filters }),

  listCompanyStatusesSuccess: (data: StatusDTO[]) =>
    action(StatusTypes.LIST_COMPANY_STATUSES_SUCCESS, data),
  listCompanyStatusesError: (error: string) =>
    action(StatusTypes.LIST_COMPANY_STATUSES_ERROR, { error }),

  listSystemStatusesRequest: (filters: SearchQuery) =>
    action(StatusTypes.LIST_SYSTEM_STATUSES_REQUEST, { filters }),

  listSystemStatusesSuccess: (data: StatusDTO[]) =>
    action(StatusTypes.LIST_SYSTEM_STATUSES_SUCCESS, data),
  listSystemStatusesError: (error: string) =>
    action(StatusTypes.LIST_SYSTEM_STATUSES_ERROR, { error }),
};
export default StatusActions;

const INITIAL_STATE: StatusState = {
  data: [],
  selected: {} as StatusDTO,
  statusFields: [],
  next: undefined,
  prev: undefined,
  filters: SearchQuery.build(),
  total: 0,
  error: '',
  loading: false,
  status: [],
  companyStatuses: [],
  systemStatuses: [],
  loadingCompanyStatuses: false,
  loadingSystemStatuses: false,
};

export type StatusReducer<Action extends AnyAction> = Reducer<
  StatusState,
  Action
>;

const listSuccess: StatusReducer<
  ReturnType<typeof StatusActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  currentPage: payload.currentPage,
  next: payload.next,
  prev: payload.prev,
  total: payload.total,
  loading: false,
});

const getSuccess: StatusReducer<ReturnType<typeof StatusActions.getSuccess>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  selected: payload,
  error: '',
  loading: false,
});

const getStatusFieldsSuccess: StatusReducer<
  ReturnType<typeof StatusActions.getStatusFieldsSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  statusFields: payload,
  error: '',
  loading: false,
});

const clearSelected: StatusReducer<
  ReturnType<typeof StatusActions.clearSelected>
> = (state = INITIAL_STATE) => ({
  ...state,
  selected: INITIAL_STATE.selected,
  error: '',
  loading: false,
});

const setFilters: StatusReducer<ReturnType<typeof StatusActions.setFilters>> = (
  state = INITIAL_STATE,
  { payload }
) => ({
  ...state,
  filters: payload.filters,
});

const listCompanyStatusesSuccess: StatusReducer<
  ReturnType<typeof StatusActions.listCompanyStatusesSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  companyStatuses: payload,
  loading: false,
});

const listSystemStatusesSuccess: StatusReducer<
  ReturnType<typeof StatusActions.listSystemStatusesSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  systemStatuses: payload,
  loading: false,
});

export const reducer = createReducer<StatusState>(INITIAL_STATE)
  .handleAction(StatusTypes.LIST_REQUEST, genericRequest)
  .handleAction(StatusTypes.LIST_SUCCESS, listSuccess)
  .handleAction(StatusTypes.LIST_ERROR, genericError)
  .handleAction(StatusTypes.GET_STATUS_FIELDS_REQUEST, genericRequest)
  .handleAction(StatusTypes.GET_STATUS_FIELDS_SUCCESS, getStatusFieldsSuccess)
  .handleAction(StatusTypes.GET_STATUS_FIELDS_ERROR, genericError)
  .handleAction(StatusTypes.GET_REQUEST, genericRequest)
  .handleAction(StatusTypes.GET_SUCCESS, getSuccess)
  .handleAction(StatusTypes.GET_ERROR, genericError)
  .handleAction(StatusTypes.ADD_REQUEST, genericRequest)
  .handleAction(StatusTypes.ADD_SUCCESS, genericSuccess)
  .handleAction(StatusTypes.ADD_ERROR, genericError)
  .handleAction(StatusTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(StatusTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(StatusTypes.UPDATE_ERROR, genericError)
  .handleAction(StatusTypes.CLEAR_SELECTED, clearSelected)
  .handleAction(StatusTypes.SET_FILTERS, setFilters)
  .handleAction(StatusTypes.LIST_COMPANY_STATUSES_REQUEST, genericRequest)
  .handleAction(
    StatusTypes.LIST_COMPANY_STATUSES_SUCCESS,
    listCompanyStatusesSuccess
  )
  .handleAction(StatusTypes.LIST_COMPANY_STATUSES_ERROR, genericError)
  .handleAction(StatusTypes.LIST_SYSTEM_STATUSES_REQUEST, genericRequest)
  .handleAction(
    StatusTypes.LIST_SYSTEM_STATUSES_SUCCESS,
    listSystemStatusesSuccess
  )
  .handleAction(StatusTypes.LIST_SYSTEM_STATUSES_ERROR, genericError);
