import PageDTO from '@/dtos/generics/PageDTO';
import CodeDTO from '@/dtos/solicitations/CodeDTO';
import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';
import { AxiosError } from 'axios';

class CodeServices {
  async findBySolicitation(solicitationId: string): Promise<string[]> {
    const { data } = await api.get<string[]>(
      `/solicitations/${solicitationId}/codes`,
      {
        params: { type: 'CREATION' },
      }
    );
    return data;
  }

  async list(params: SearchQuery): Promise<PageDTO<CodeDTO>> {
    const { data } = await api.get<PageDTO<CodeDTO>>('/codes', {
      params: params.query,
    });
    return data;
  }

  async find(id: string): Promise<CodeDTO> {
    try {
      const { data } = await api.get<CodeDTO>(`/codes/${id}`);
      return data;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        throw axiosError;
      }
      throw error;
    }
  }
}

const codeServices = new CodeServices();

export default codeServices;
