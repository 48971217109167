import { Reducer, AnyAction } from 'redux';
import { action, createReducer } from 'typesafe-actions';
import SearchQuery from '@/utils/SearchQuery';
import { PageDTO } from '@/dtos/generics';
import { genericRequest, genericError, genericSuccess } from '../utils';
import AggregationDTO from '@/dtos/aggregations/AggregationDTO';
import { CreateAggregationDTO } from '@/dtos/aggregations/CreateAggregationDTO';
import { UpdateAggregationDTO } from '@/dtos/aggregations/UpdateAggregationDTO';

export interface AggregationsState {
  readonly data: AggregationDTO[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly filters: SearchQuery;
  readonly total: number;
  readonly selected?: AggregationDTO;
  readonly currentPage: number;
  readonly next?: number;
  readonly prev?: number;
}

const INITIAL_STATE: AggregationsState = {
  data: [],
  loading: false,
  error: false,
  filters: SearchQuery.build(),
  total: 0,
  currentPage: 1,
  selected: undefined,
  next: undefined,
  prev: undefined,
};

export enum AggregationTypes {
  SET_LOADING = '@aggregations/SET_LOADING',
  SET_FILTERS = '@aggregations/SET_FILTERS',
  LIST_REQUEST = '@aggregations/LIST_REQUEST',
  LIST_SUCCESS = '@aggregations/LIST_SUCCESS',
  LIST_ERROR = '@aggregations/LIST_ERROR',
  GET_REQUEST = '@aggregations/GET_REQUEST',
  GET_SUCCESS = '@aggregations/GET_SUCCESS',
  GET_ERROR = '@aggregations/GET_ERROR',
  CREATE_REQUEST = '@aggregations/CREATE_REQUEST',
  CREATE_SUCCESS = '@aggregations/CREATE_SUCCESS',
  CREATE_ERROR = '@aggregations/CREATE_ERROR',
  UPDATE_REQUEST = '@aggregations/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@aggregations/UPDATE_SUCCESS',
  UPDATE_ERROR = '@aggregations/UPDATE_ERROR',
  DISAGGREGATE_REQUEST = '@aggregations/DISAGGREGATE_REQUEST',
  DISAGGREGATE_SUCCESS = '@aggregations/DISAGGREGATE_SUCCESS',
  DISAGGREGATE_ERROR = '@aggregations/DISAGGREGATE_ERROR',
  CLEAR = '@aggregations/CLEAR',
}

const AggregationsActions = {
  setLoading: (loading: boolean) =>
    action(AggregationTypes.SET_LOADING, { loading }),
  setFilters: (filters: SearchQuery) =>
    action(AggregationTypes.SET_FILTERS, { filters }),

  listRequest: (filters: SearchQuery, page?: number) =>
    action(AggregationTypes.LIST_REQUEST, { filters, page }),

  listSuccess: (page: PageDTO<AggregationDTO>) =>
    action(AggregationTypes.LIST_SUCCESS, page),
  listError: (error: string) => action(AggregationTypes.LIST_ERROR, { error }),

  getRequest: (id: string) => action(AggregationTypes.GET_REQUEST, id),
  getSuccess: (aggregation: AggregationDTO) =>
    action(AggregationTypes.GET_SUCCESS, aggregation),
  getError: (error: string) => action(AggregationTypes.GET_ERROR, { error }),

  createRequest: (data: CreateAggregationDTO) =>
    action(AggregationTypes.CREATE_REQUEST, data),
  createSuccess: (aggregation: AggregationDTO) =>
    action(AggregationTypes.CREATE_SUCCESS, aggregation),
  createError: (error: string) =>
    action(AggregationTypes.CREATE_ERROR, { error }),

  updateRequest: (data: UpdateAggregationDTO) =>
    action(AggregationTypes.UPDATE_REQUEST, data),
  updateSuccess: (aggregation: AggregationDTO) =>
    action(AggregationTypes.UPDATE_SUCCESS, aggregation),
  updateError: (error: string) =>
    action(AggregationTypes.UPDATE_ERROR, { error }),

  disaggregateRequest: (aggregatorCode: string, childCode: string) =>
    action(AggregationTypes.DISAGGREGATE_REQUEST, {
      aggregatorCode,
      childCode,
    }),
  disaggregateSuccess: () => action(AggregationTypes.DISAGGREGATE_SUCCESS),
  disaggregateError: (error: string) =>
    action(AggregationTypes.DISAGGREGATE_ERROR, { error }),

  clear: () => action(AggregationTypes.CLEAR),
};

export default AggregationsActions;

type AggregationsReducer<Action extends AnyAction> = Reducer<
  AggregationsState,
  Action
>;

const setLoading: AggregationsReducer<
  ReturnType<typeof AggregationsActions.setLoading>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  loading: payload.loading,
});

const setFilters: AggregationsReducer<
  ReturnType<typeof AggregationsActions.setFilters>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  filters: payload.filters,
});

const listSuccess: AggregationsReducer<
  ReturnType<typeof AggregationsActions.listSuccess>
> = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    data: payload.data,
    currentPage: payload.currentPage,
    next: payload.next,
    prev: payload.prev,
    total: payload.total,
    loading: false,
  };
};

const getSuccess: AggregationsReducer<
  ReturnType<typeof AggregationsActions.getSuccess>
> = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    selected: payload,
    loading: false,
  };
};

const createSuccess: AggregationsReducer<
  ReturnType<typeof AggregationsActions.createSuccess>
> = (state = INITIAL_STATE, { payload }) => {
  return {
    ...state,
    data: [...state.data, payload],
    loading: false,
  };
};

const clear: AggregationsReducer<
  ReturnType<typeof AggregationsActions.clear>
> = () => INITIAL_STATE;

export const reducer = createReducer<AggregationsState>(INITIAL_STATE)
  .handleAction(AggregationTypes.SET_LOADING, setLoading)
  .handleAction(AggregationTypes.SET_FILTERS, setFilters)
  .handleAction(AggregationTypes.LIST_REQUEST, genericRequest)
  .handleAction(AggregationTypes.LIST_SUCCESS, listSuccess)
  .handleAction(AggregationTypes.LIST_ERROR, genericError)
  .handleAction(AggregationTypes.GET_REQUEST, genericRequest)
  .handleAction(AggregationTypes.GET_SUCCESS, getSuccess)
  .handleAction(AggregationTypes.GET_ERROR, genericError)
  .handleAction(AggregationTypes.CREATE_REQUEST, genericRequest)
  .handleAction(AggregationTypes.CREATE_SUCCESS, createSuccess)
  .handleAction(AggregationTypes.CREATE_ERROR, genericError)
  .handleAction(AggregationTypes.UPDATE_REQUEST, genericRequest)
  .handleAction(AggregationTypes.UPDATE_SUCCESS, genericSuccess)
  .handleAction(AggregationTypes.UPDATE_ERROR, genericError)
  .handleAction(AggregationTypes.DISAGGREGATE_REQUEST, genericRequest)
  .handleAction(AggregationTypes.DISAGGREGATE_SUCCESS, genericSuccess)
  .handleAction(AggregationTypes.DISAGGREGATE_ERROR, genericError)
  .handleAction(AggregationTypes.CLEAR, clear);
