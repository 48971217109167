import AddEventDTO from '@/dtos/events/AddEventDTO';
import EventErrorDTO from '@/dtos/events/EventErrorDTO';
import { PageDTO } from '@/dtos/generics';
import EventDTO from '@/dtos/events/EventDTO';
import FindEventDTO from '@/dtos/events/FindEventDTO';
import api from '@/services/api';
import SearchQuery from '@/utils/SearchQuery';
import CreateEntranceDTO from '@/dtos/events/CreateEntranceDTO';

class EventServices {
  async findByCode({
    code,
    params,
    ...rest
  }: FindEventDTO): Promise<PageDTO<EventDTO>> {
    const { data } = await api.get<PageDTO<EventDTO>>(`/events/code/${code}`, {
      params: { ...params.query, ...rest },
    });
    return data;
  }
  async create(events: AddEventDTO[]): Promise<EventErrorDTO[]> {
    const { data } = await api.post<EventErrorDTO[]>('/events', events);
    return data;
  }
  async entrance(events: CreateEntranceDTO): Promise<EventErrorDTO[]> {
    const { data } = await api.post<EventErrorDTO[]>('/entrance', events);
    return data;
  }
  async list(params: SearchQuery): Promise<PageDTO<EventDTO>> {
    const { data } = await api.get<PageDTO<EventDTO>>('/events', {
      params: params.query,
    });
    // console.log(data);
    return data;
  }
  async download(params: SearchQuery): Promise<string> {
    const { data } = await api.get<string>('/events/export', {
      params: params.query,
    });
    return data;
  }
}

const eventServices = new EventServices();

export default eventServices;
