import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import handleError from '@/adapters/httpErrors';
import resourceProvider from '@/lib/resources';
import { PageDTO } from '@/dtos/generics';
import { ResourceDTO } from '@/dtos/resource';
import ResourceActions, { ResourceTypes } from './duck';
import history from '@/services/history';
import ResourceFieldDTO from '@/dtos/resourceField/ResourceFieldDTO';
import resourceFieldProvider from '@/lib/resourceField';
import ResourceFieldActions from '../resourceField/duck';

type SignUpParams = ReturnType<typeof ResourceActions.listRequest>;

export function* list({ payload }: SignUpParams): Generator {
  try {
    const result = yield call(resourceProvider.list, payload.filters);
    yield put(ResourceActions.listSuccess(result as PageDTO<ResourceDTO>));
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.listError(errors));
  }
}
export function* listAll(): Generator {
  try {
    const result = yield call(resourceProvider.listAll);
    yield put(ResourceActions.listAllSuccess(result as ResourceDTO[]));
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.listAllError(errors));
  }
}

type AddParams = ReturnType<typeof ResourceActions.addRequest>;
export function* add({ payload }: AddParams): Generator {
  try {
    yield call(resourceProvider.create, payload.data);
    yield put(ResourceActions.addSuccess());
    if (payload.redirect) {
      history.push('/resources');
    }
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.addError(errors));
  }
}

type UpdateParams = ReturnType<typeof ResourceActions.updateRequest>;
export function* update({ payload }: UpdateParams): Generator {
  try {
    yield call(resourceProvider.update, payload);
    yield put(ResourceActions.updateSuccess());
    history.push('/resources');
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.updateError(errors));
  }
}

type UploadImageParams = ReturnType<typeof ResourceActions.uploadImageRequest>;
export function* uploadImage({ payload }: UploadImageParams): Generator {
  try {
    yield call(resourceProvider.uploadImage, payload);
    yield put(ResourceActions.updateSuccess());
    toast.success('image uploaded');
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.updateError(errors));
  }
}

type ToggleParams = ReturnType<typeof ResourceActions.toggleVisibilityRequest>;
export function* toggleResource({ payload }: ToggleParams): Generator {
  try {
    const data = yield call(resourceProvider.toggleResourceState, payload);
    yield put(ResourceActions.getSuccess(data as ResourceDTO));
    yield put(ResourceActions.toggleVisibilitySuccess());
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.toggleVisibilityError(errors));
  }
}

type ToggleInteractionParams = ReturnType<
  typeof ResourceActions.toggleInteractionRequest
>;
export function* toggleInteractionResource({
  payload,
}: ToggleInteractionParams): Generator {
  try {
    yield call(resourceProvider.toggleResourceInteraction, payload);
    toast.success('interaction updated');
    yield put(ResourceActions.toggleInteractionSuccess());
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.toggleInteractionError(errors));
  }
}

type UpdateDisplayStatusParams = ReturnType<
  typeof ResourceActions.updateResourceDisplayStatusRequest
>;
export function* updateDisplayStatusResource({
  payload,
}: UpdateDisplayStatusParams): Generator {
  try {
    yield call(resourceProvider.updateDisplayStatus, payload);
    toast.success('resource status updated');
    yield put(ResourceActions.updateDisplayStatusSuccess());
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.updateDisplayStatusError(errors));
  }
}

type GetParams = ReturnType<typeof ResourceActions.getRequest>;
export function* get({ payload }: GetParams): Generator {
  try {
    const data = yield call(resourceProvider.getById, payload);
    yield put(ResourceActions.getSuccess(data as ResourceDTO));
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.getError(errors));
  }
}

type SignUpParamsFields = ReturnType<typeof ResourceFieldActions.listRequest>;
export function* getListFields({ payload }: SignUpParamsFields): Generator {
  try {
    const result = yield call(resourceFieldProvider.list, payload.filters);
    yield put(
      ResourceActions.getListFieldsSuccess(result as ResourceFieldDTO[])
    );
  } catch (err) {
    const errors = handleError(err);
    yield put(ResourceActions.getListFieldsError(errors));
  }
}

export default all([
  takeLatest(ResourceTypes.LIST_REQUEST, list),
  takeLatest(ResourceTypes.LIST_ALL_REQUEST, listAll),
  takeLatest(ResourceTypes.ADD_REQUEST, add),
  takeLatest(ResourceTypes.UPLOAD_IMAGE_REQUEST, uploadImage),
  takeLatest(ResourceTypes.UPDATE_REQUEST, update),
  takeLatest(ResourceTypes.TOGGLE_VISIBILITY_REQUEST, toggleResource),
  takeLatest(
    ResourceTypes.TOGGLE_INTERACTION_REQUEST,
    toggleInteractionResource
  ),
  takeLatest(
    ResourceTypes.UPDATE_DISPLAY_STATUS_REQUEST,
    updateDisplayStatusResource
  ),
  takeLatest(ResourceTypes.GET_REQUEST, get),
  takeLatest(ResourceTypes.GET_LIST_FIELDS_REQUEST, getListFields),
]);
