import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isBefore, parseISO } from 'date-fns';

import { ApplicationState } from '@/store/types';
import { checkMenuItems } from '@/store/modules/sidebar/duck';
import UserActions from '@/store/modules/user/duck';
import Sidebar from '@/components/Layout/Sidebar';
import Loader from '@/components/Loader';
import Route from '@/routes/Route';
import useCheckPermissionCallback from '@/hooks/useCheckPermissionCallback';
import useCheckFeatureCallback from '@/hooks/useCheckFeatureCallback';

import { Container, Content, Main } from './styles';

// users
const UsersList = lazy(() => import('@/pages/Users/List'));
const UsersSave = lazy(() => import('@/pages/Users/Save'));
const UsersEdit = lazy(() => import('@/pages/Users/Edit'));
// locations
const LocationsSave = lazy(() => import('@/pages/Locations/Save'));
const LocationsList = lazy(() => import('@/pages/Locations/List'));
const LocationsEdit = lazy(() => import('@/pages/Locations/Edit'));
// locationPositions
const LocationPositionSave = lazy(
  () => import('@/pages/LocationPositions/Save')
);
const LocationPositionsEdit = lazy(
  () => import('@/pages/LocationPositions/Edit')
);
const LocationPositionsList = lazy(
  () => import('@/pages/LocationPositions/List')
);

// codes
const CodesList = lazy(() => import('@/pages/Codes/List'));
const CodeDetails = lazy(() => import('@/pages/Codes/Details'));
// companies
const CompaniesList = lazy(() => import('@/pages/Companies/List'));
const CompaniesSave = lazy(() => import('@/pages/Companies/Save'));
const CompaniesEdit = lazy(() => import('@/pages/Companies/Edit'));
// plans
const PlansList = lazy(() => import('@/pages/Plans/List'));
const PlansSave = lazy(() => import('@/pages/Plans/Save'));
const PlansEdit = lazy(() => import('@/pages/Plans/Edit'));

// Rolls
const RollsSave = lazy(() => import('@/pages/Rolls/Save'));
const RollsList = lazy(() => import('@/pages/Rolls/List'));
const RollsPrint = lazy(() => import('@/pages/Rolls/Print'));

// users
const ResourcesList = lazy(() => import('@/pages/Resources/List'));
const ResourcesSave = lazy(() => import('@/pages/Resources/Save'));
const ResourcesEdit = lazy(() => import('@/pages/Resources/Edit'));
// resourceField
const ResourceFieldSave = lazy(() => import('@/pages/ResourceField/Save'));
const ResourceFieldList = lazy(() => import('@/pages/ResourceField/List'));
const ResourceFieldEdit = lazy(() => import('@/pages/ResourceField/Edit'));
// settings
const Settings = lazy(() => import('@/pages/Settings'));
// safety
const SafetySave = lazy(() => import('@/pages/Safety/Save'));
const SafetyEdit = lazy(() => import('@/pages/Safety/Edit'));
// event
const Tracking = lazy(() => import('@/pages/Tracking'));
const AddEvent = lazy(() => import('@/pages/AddEvent'));
const AutomaticActivation = lazy(() => import('@/pages/AutomaticActivation'));
const AggregationAndEventsInProduction = lazy(
  () => import('@/pages/AggregationAndEventsInProduction')
);

//licensedCode
const LicensedCodeList = lazy(() => import('@/pages/LicensedCodes/List'));
const LicensedCodeSave = lazy(() => import('@/pages/LicensedCodes/Save'));

//interaction
const InteractionConfig = lazy(() => import('@/pages/InteractionsConfig'));
const TotalReport = lazy(() => import('@/pages/InteractionReportTotal'));
const DetailedReport = lazy(
  () => import('@/pages/InteractionDetailedReportReport')
);
const InteractionHeatMapReport = lazy(
  () => import('@/pages/InteractionReportHeatMap')
);
const ReducedReport = lazy(() => import('@/pages/InteractionReportResumido'));

const Packing = lazy(() => import('@/pages/Packing'));
const Entrance = lazy(() => import('@/pages/Entrance'));
const EventsList = lazy(() => import('@/pages/Events/List'));
// status
const StatusList = lazy(() => import('@/pages/Status/List'));
const StatusSave = lazy(() => import('@/pages/Status/Save'));
// status fields
const StatusFieldList = lazy(() => import('@/pages/StatusField/List'));
const StatusFieldSave = lazy(() => import('@/pages/StatusField/Save'));
const StatusFieldEdit = lazy(() => import('@/pages/StatusField/Edit'));
const StatusDetails = lazy(() => import('@/pages/Status/Details'));

// invoices
const InvoicesList = lazy(() => import('@/pages/Invoices/List'));
const InvoicesSave = lazy(() => import('@/pages/Invoices/Save'));
const InvoicesDetails = lazy(() => import('@/pages/Invoices/Details'));
// printers
const PrintersList = lazy(() => import('@/pages/Printers/List'));
const PrintersSave = lazy(() => import('@/pages/Printers/Save'));
const PrintersEdit = lazy(() => import('@/pages/Printers/Edit'));

const AppRoute: React.FC<{ children?: JSX.Element }> = () => {
  const { t: translation } = useTranslation();
  const checkPermission = useCheckPermissionCallback();
  const checkFeature = useCheckFeatureCallback();
  const items = useSelector((store: ApplicationState) =>
    checkMenuItems(store.sidebar.items, checkPermission, checkFeature)
  );
  const dispatch = useDispatch();
  const sessionDue = useSelector(
    ({ user }: ApplicationState) => user.sessionDue
  );

  const firstPageWithPermission = (): string => {
    const firstPage = items[0];
    const firstPageSubItem = firstPage?.items && firstPage?.items[0];
    return firstPage?.to || firstPageSubItem?.to || '';
  };

  useEffect(() => {
    if (isBefore(new Date(), parseISO(sessionDue))) return;
    dispatch(UserActions.logoutRequest());
  }, [dispatch, sessionDue]);

  return (
    <Container>
      <Sidebar />
      <Main>
        <Content id="main-content">
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to={firstPageWithPermission()} />}
              />
              <Route
                exact
                path="/users"
                permission="index:user"
                component={UsersList}
              />
              <Route
                path="/users/add"
                permission="store:user"
                component={UsersSave}
              />
              <Route
                path="/users/edit/:id"
                permission="update:user"
                component={UsersEdit}
              />

              <Route
                exact
                path="/companies"
                permission="index:company"
                component={CompaniesList}
              />
              <Route
                path="/companies/add"
                permission="store:company"
                component={CompaniesSave}
              />
              <Route
                path="/companies/edit/:id"
                permission="update:company"
                component={CompaniesEdit}
              />

              <Route
                exact
                path="/resources"
                permission="index:resource"
                component={ResourcesList}
              />
              <Route
                path="/resources/add"
                permission="store:resource"
                component={ResourcesSave}
              />
              <Route
                path="/resources/edit/:id"
                component={ResourcesEdit}
                permission="update:resource"
              />
              <Route
                path="/resource-fields/add"
                component={ResourceFieldSave}
              />
              <Route
                exact
                path="/resource-fields"
                permission=""
                component={ResourceFieldList}
              />
              <Route
                path="/resource-fields/edit/:id"
                component={ResourceFieldEdit}
                permission="update:resource"
              />
              <Route
                exact
                path="/locations/add"
                permission=""
                component={LocationsSave}
              />
              <Route
                exact
                path="/locations"
                permission=""
                component={LocationsList}
              />
              <Route
                exact
                path="/locations/edit/:id"
                permission=""
                component={LocationsEdit}
              />
              <Route
                exact
                path="/location-positions/add"
                permission=""
                component={LocationPositionSave}
              />
              <Route
                exact
                path="/location-positions/edit/:id"
                permission=""
                component={LocationPositionsEdit}
              />
              <Route
                exact
                path="/location-positions"
                permission=""
                component={LocationPositionsList}
              />
              <Route
                exact
                path="/plans"
                permission="index:plan"
                component={PlansList}
              />
              <Route
                path="/plans/add"
                permission="store:plan"
                component={PlansSave}
              />
              <Route
                exact
                path="/plans/edit/:id"
                permission=""
                component={PlansEdit}
              />
              <Route
                exact
                path="/security"
                permission="index:role"
                component={SafetySave}
              />
              <Route
                path="/security/role/edit/:id"
                permission="update:role"
                component={SafetyEdit}
              />

              <Route
                exact
                path="/status"
                permission="index:status"
                component={StatusList}
              />
              <Route
                path="/status/add"
                permission="store:status"
                component={StatusSave}
              />
              <Route
                path="/status/edit/:id"
                component={StatusSave}
                permission="update:status"
              />

              <Route
                exact
                path="/status-fields"
                permission=""
                component={StatusFieldList}
              />
              <Route
                path="/status-fields/add"
                permission=""
                component={StatusFieldSave}
              />
              <Route
                path="/status-fields/edit/:id"
                permission=""
                component={StatusFieldEdit}
              />

              <Route
                exact
                path="/status/details/:id"
                component={StatusDetails}
              />

              <Route
                exact
                path="/invoices"
                permission="index:invoice"
                component={InvoicesList}
              />
              <Route
                path="/invoices/import"
                permission="store:invoice"
                component={InvoicesSave}
              />
              <Route
                path="/invoices/:id/details"
                component={InvoicesDetails}
                permission="show:invoice"
              />
              <Route
                path="/codes/:value/details"
                component={CodeDetails}
                permission=""
              />
              <Route
                exact
                path="/tracking"
                permission="index:event"
                component={Tracking}
              />
              <Route path="/packing" component={Packing} />
              <Route
                exact
                path="/interaction/config"
                permission=""
                component={InteractionConfig}
              />
              <Route
                exact
                path="/interaction/total-report"
                permission=""
                component={TotalReport}
              />
              <Route
                exact
                path="/interaction/detailed-report"
                permission=""
                component={DetailedReport}
              />
              <Route
                exact
                path="/interaction/reduced-report"
                permission=""
                component={ReducedReport}
              />
              <Route
                exact
                path="/interaction/heatMap-report"
                permission=""
                component={InteractionHeatMapReport}
              />
              <Route
                exact
                path="/interaction/heatMap-report"
                permission=""
                component={InteractionHeatMapReport}
              />
              <Route
                exact
                path="/licenQrCode"
                permission=""
                component={LicensedCodeList}
              />
              <Route
                exact
                path="/licenQrCode/add"
                permission=""
                component={LicensedCodeSave}
              />
              <Route
                exact
                path="/rolls"
                permission="index:roll"
                component={RollsList}
              />
              <Route
                exact
                path="/rolls/:id/print"
                permission="index:roll"
                component={RollsPrint}
              />
              {
                <Route
                  exact
                  path="/rolls/add"
                  permission="store:roll"
                  component={RollsSave}
                />
              }
              <Route path="/Entrance" component={Entrance} />
              <Route exact path="/events" component={EventsList} />
              <Route exact path="/codes" component={CodesList} permission="" />
              <Route
                exact
                path="/events/add"
                permission="index:event"
                component={AddEvent}
              />
              <Route
                exact
                path="/activation/add"
                permission="index:event"
                component={AutomaticActivation}
              />
              <Route
                exact
                path="/aggregationAndEvents/add"
                permission="index:event"
                component={AggregationAndEventsInProduction}
              />

              <Route path="/printers" exact component={PrintersList} />
              <Route path="/printers/add" component={PrintersSave} />
              <Route path="/printers/edit/:id" component={PrintersEdit} />

              <Route exact path="/settings" component={Settings} />
              <Route
                component={() => {
                  toast.error(translation('error.validation.page_not_found'));

                  return <Redirect to={firstPageWithPermission()} />;
                }}
              />
            </Switch>
          </Suspense>
        </Content>
      </Main>
    </Container>
  );
};

export default AppRoute;
