import { all, takeLatest, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import statusProvider from '@/lib/status';
import handleError from '@/adapters/httpErrors';
import { StatusDTO } from '@/dtos/status';
import { PageDTO } from '@/dtos/generics';
import { StatusFieldDTO } from '@/dtos/statusField';
import history from '@/services/history';

import StatusActions, { StatusTypes } from './duck';

export function* getStatusFields(): SagaIterator {
  try {
    const data: StatusFieldDTO[] = yield call(statusProvider.getStatusFields);
    yield put(StatusActions.getStatusFieldsSuccess(data));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.getStatusFieldsError(error));
  }
}

type ListParams = ReturnType<typeof StatusActions.listRequest>;
export function* list({ payload }: ListParams): SagaIterator {
  try {
    const result: PageDTO<StatusDTO> = yield call(
      statusProvider.list,
      payload.filters
    );
    yield put(StatusActions.listSuccess(result));
  } catch (err) {
    const errors = handleError(err);
    yield put(StatusActions.listError(errors));
  }
}

type GetParams = ReturnType<typeof StatusActions.getRequest>;
export function* get({ payload }: GetParams): SagaIterator {
  try {
    const status: StatusDTO = yield call(statusProvider.get, payload);
    yield put(StatusActions.getSuccess(status));
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.getError(error));
  }
}

type AddParams = ReturnType<typeof StatusActions.addRequest>;
export function* add({ payload }: AddParams): SagaIterator {
  try {
    yield call(statusProvider.create, payload);
    yield put(StatusActions.addSuccess());
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.addError(error));
  }
}

type UpdateParams = ReturnType<typeof StatusActions.updateRequest>;
export function* update({ payload }: UpdateParams): SagaIterator {
  try {
    yield call(statusProvider.update, payload);
    yield put(StatusActions.updateSuccess());
    history.goBack();
  } catch (err) {
    const error = handleError(err);
    yield put(StatusActions.updateError(error));
  }
}

type ListCompanyStatusesParams = ReturnType<
  typeof StatusActions.listCompanyStatusesRequest
>;
export function* listCompanyStatuses({
  payload,
}: ListCompanyStatusesParams): SagaIterator {
  try {
    const result: PageDTO<StatusDTO> = yield call(
      statusProvider.list,
      payload.filters
    );
    yield put(StatusActions.listCompanyStatusesSuccess(result.data));
  } catch (err) {
    const errors = handleError(err);
    yield put(StatusActions.listCompanyStatusesError(errors));
  }
}

type ListSystemStatusesParams = ReturnType<
  typeof StatusActions.listSystemStatusesRequest
>;
export function* listSystemStatuses({
  payload,
}: ListSystemStatusesParams): SagaIterator {
  try {
    const result: PageDTO<StatusDTO> = yield call(
      statusProvider.list,
      payload.filters
    );
    yield put(StatusActions.listSystemStatusesSuccess(result.data));
  } catch (err) {
    const errors = handleError(err);
    yield put(StatusActions.listSystemStatusesError(errors));
  }
}

export default all([
  takeLatest(StatusTypes.GET_STATUS_FIELDS_REQUEST, getStatusFields),
  takeLatest(StatusTypes.GET_REQUEST, get),
  takeLatest(StatusTypes.ADD_REQUEST, add),
  takeLatest(StatusTypes.UPDATE_REQUEST, update),
  takeLatest(StatusTypes.LIST_REQUEST, list),
  takeLatest(StatusTypes.GET_STATUS_REQUEST, get),
  takeLatest(StatusTypes.LIST_COMPANY_STATUSES_REQUEST, listCompanyStatuses),
  takeLatest(StatusTypes.LIST_SYSTEM_STATUSES_REQUEST, listSystemStatuses),
]);
